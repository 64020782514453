import { Fragment as _Fragment, jsx as _jsx } from "react/jsx-runtime";
import { useEffect } from "react";
import { KankanScene } from "../service/SceneManagement/KankanScene";
import { SceneManager } from "../service/SceneManager";
import { NativeScene } from "../service/SceneManagement/NativeScene";
import { SCENE_PLAYER_LOADED } from "../state/const";
import { url } from "services/ApiEndpoint";
import { Vector3, Vector4 } from "three";
export default function SceneLoader(props) {
    useEffect(() => {
        const container = document.getElementById(props.containerId);
        if (!container)
            return;
        let scene;
        if (props.templateInfo.type === "4DAGE") {
            scene = new KankanScene("KJ-eur-zQvLb2c574", props.mode);
            scene.createDOM(container);
        }
        else {
            scene = new NativeScene(url(`/scene-data/${props.templateInfo.sceneModelPath}`).value, props.mode);
            const dom = scene.createDOM(container);
            scene.createCanvas(dom);
        }
        window.addEventListener(SCENE_PLAYER_LOADED, (e) => {
            const player = e.detail;
            const initPosition = new Vector3(props.templateInfo.initPosition.x, props.templateInfo.initPosition.y, props.templateInfo.initPosition.z);
            const initRotation = new Vector4(props.templateInfo.initRotation.x, props.templateInfo.initRotation.y, props.templateInfo.initRotation.z, props.templateInfo.initRotation.w);
            player.position = initPosition;
            player.rotation = initRotation;
        });
        const sceneManager = new SceneManager(scene, props.mode, props.userInfo, props.roomInfo, props.eventId);
        sceneManager.run();
    }, []);
    return _jsx(_Fragment, {});
}
