import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faImage, faVideo, faCube, faEdit, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { ADD_NEW_IMAGE_IN_SCENE_MODEL, ADD_NEW_OBJECT_IN_SCENE_MODEL, ADD_NEW_VIDEO_IN_SCENE_MODEL, DELETE_ITEM_IN_SCENE_MODEL, EDIT_SCENE_ITEM, RIGHT_CLICK_IN_SCENE_MODEL, } from "../state/const";
const iconStyle = {
    fontSize: "24px",
    color: "#000",
    marginRight: "5px",
};
const labelStyle = {
    fontSize: "12px",
    color: "#000",
};
export default function ModelContextMenu(props) {
    const [isEditMode, setEditmode] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null);
    useEffect(() => {
        props.scene.renderer.domElement.addEventListener(RIGHT_CLICK_IN_SCENE_MODEL, () => {
            if (props.scene.sceneInteractionDetail) {
                if (props.scene.sceneInteractionDetail.customItem) {
                    setSelectedItem(props.scene.sceneInteractionDetail.object);
                }
                setEditmode(props.scene.sceneInteractionDetail.customItem);
            }
        });
    }, []);
    const dispatchEvent = (event) => {
        const myEvent = new CustomEvent(event);
        props.scene.renderer.domElement.dispatchEvent(myEvent);
    };
    const menuItems = isEditMode
        ? [
            {
                icon: faEdit,
                action: () => {
                    const customEvent = new CustomEvent(EDIT_SCENE_ITEM, {
                        detail: selectedItem,
                    });
                    props.scene.renderer.domElement.dispatchEvent(customEvent);
                },
                label: "Edit",
            },
            {
                icon: faTrashAlt,
                action: () => {
                    const customEvent = new CustomEvent(DELETE_ITEM_IN_SCENE_MODEL, {
                        detail: selectedItem,
                    });
                    props.scene.renderer.domElement.dispatchEvent(customEvent);
                },
                label: "Delete",
            },
        ]
        : [
            {
                icon: faImage,
                action: () => {
                    dispatchEvent(ADD_NEW_IMAGE_IN_SCENE_MODEL);
                },
                label: "Add Image",
            },
            {
                icon: faVideo,
                action: () => {
                    dispatchEvent(ADD_NEW_VIDEO_IN_SCENE_MODEL);
                },
                label: "Add Video",
            },
            {
                icon: faCube,
                action: () => {
                    dispatchEvent(ADD_NEW_OBJECT_IN_SCENE_MODEL);
                },
                label: "Add Object3D",
            },
        ];
    return (_jsx("div", Object.assign({ style: {
            pointerEvents: "all",
            transform: "translate(50%, 50%)",
            background: "rgba(255, 255, 255, 0.5)",
        } }, { children: menuItems.map((item, index) => (_jsxs("div", Object.assign({ style: {
                padding: "5px 10px",
                cursor: "pointer",
            }, onClick: item.action }, { children: [_jsx(FontAwesomeIcon, { icon: item.icon, style: iconStyle }), _jsx("span", Object.assign({ style: labelStyle }, { children: item.label }))] }), index))) })));
}
