import { jsx as _jsx } from "react/jsx-runtime";
import FormSelect from "components/generic/form/FormSelect";
import { useEffect, useState } from "react";
import { LOCAL, url } from "services/ApiEndpoint";
export default function Video({ item, formData, id, }) {
    const [element, setElement] = useState(item.meshToData());
    const [avalableVideos, setAvailableVideos] = useState([]);
    useEffect(() => {
        const u = url(`/room/media/get/${id}/videos`).value;
        fetch(u).then(async (res) => {
            const r = await res.json();
            setAvailableVideos(r);
        });
    }, []);
    const prevHander = formData.handleChange;
    formData.handleChange = (e) => {
        updateVideo(e);
        prevHander(e);
    };
    const updateVideo = (e) => {
        element.src = e.target.value;
        console.log(element);
        item.dataToMesh(element);
    };
    const [opt, setOpt] = useState([]);
    useEffect(() => {
        const o = [
            {
                key: `${LOCAL}/static/example.mp4`,
                value: `example.mp4`,
            },
        ];
        avalableVideos.map((op) => {
            o.push({
                key: url(`/room/media/get/${id}${op.src}`).value,
                value: op.displayName,
            });
        });
        setOpt(o);
    }, [avalableVideos]);
    return opt.length > 0 ? _jsx(FormSelect, { formik: formData, name: "mediaValue", options: opt }) : null;
}
