// SCENE LOADING EVENTS
const SCENE_LOADED = "scene-loaded";
const SCENE_PLAYER_LOADED = "scene-player-loaded";
/// SCENE MODEL CLICKE EVENTS
const LEFT_CLICK_IN_SCENE_MODEL = "left-click-in-scene-model";
const RIGHT_CLICK_IN_SCENE_MODEL = "right-click-in-scene-model";
// SCENE MODEL ACTION EVENTS
const ADD_NEW_IMAGE_IN_SCENE_MODEL = "add-new-image-in-scene-model";
const ADD_NEW_VIDEO_IN_SCENE_MODEL = "add-new-video-in-scene-model";
const ADD_NEW_OBJECT_IN_SCENE_MODEL = "add-new-object-in-scene-model";
const EDIT_ITEM_IN_SCENE_MODEL = "edit-item-in-scene-model";
const DELETE_ITEM_IN_SCENE_MODEL = "delete-item-in-scene-model";
const SAVE_CUSTOM_ITEMS_DETAILS = "save-custom-items-details";
// SCENE GROUP ACTION
const ITEM_ADDED_TO_THE_SCENE_GROUP = "item-added-to-the-group";
const ITEM_REMOVED_FROM_THE_SCENE_GROUP = "item-removed-from-the-group";
const EDIT_SCENE_ITEM = "edit-scene-item";
const CLOSE_SCENE_ITEM_EDITOR = "close-scene-item-editor";
const RESET_EDIT_ITEM = "reset-edit-item";
const LOAD_JSON_ITEMS = "load-json-items";
// UI
const UPDATE_UI = "update-ui";
// PRESENTAION YOUNIVR ITEM CLICKED
const YOUNIVR_OBJECT_CLICKED = "YounivrObjectClicked";
const MODEL_CLEAN_UP_EVENT = "model-clean-up-event";
// SOCKET ID
const SOCKET_USER_ID = "socket-user-id";
export { LEFT_CLICK_IN_SCENE_MODEL, RIGHT_CLICK_IN_SCENE_MODEL, ADD_NEW_IMAGE_IN_SCENE_MODEL, ADD_NEW_OBJECT_IN_SCENE_MODEL, ADD_NEW_VIDEO_IN_SCENE_MODEL, EDIT_ITEM_IN_SCENE_MODEL, DELETE_ITEM_IN_SCENE_MODEL, ITEM_ADDED_TO_THE_SCENE_GROUP, ITEM_REMOVED_FROM_THE_SCENE_GROUP, SOCKET_USER_ID, SCENE_PLAYER_LOADED, YOUNIVR_OBJECT_CLICKED, MODEL_CLEAN_UP_EVENT, SCENE_LOADED, EDIT_SCENE_ITEM, CLOSE_SCENE_ITEM_EDITOR, RESET_EDIT_ITEM, UPDATE_UI, SAVE_CUSTOM_ITEMS_DETAILS, LOAD_JSON_ITEMS, };
