import { DoubleSide, Mesh, MeshBasicMaterial, PlaneGeometry, VideoTexture } from "three";
import { SceneItem } from "../model/SceneItem";
export class VideoMesh extends Mesh {
    constructor() {
        super(new PlaneGeometry(1, 1), new MeshBasicMaterial());
        this.type = "VideoMesh";
        this.name = "VideoPlane";
        this.material.side = DoubleSide;
    }
    getVideoElement() {
        return this.videoElement;
    }
    dataToMesh(data) {
        var _a;
        this.clear();
        this.position.copy(data.position);
        this.rotation.copy(data.rotation);
        this.scale.copy(data.scale);
        this.src = data.src;
        this.name = data.name;
        this.userData.displayName = (_a = data.displayName) !== null && _a !== void 0 ? _a : this.uuid;
        this.userData.description = data.description;
        this.updateSrc();
        this.material.needsUpdate;
    }
    updateSrc() {
        const video = document.createElement("video");
        // TODO add toggle button to switch on / off sound
        video.src = this.src;
        video.autoplay = true;
        video.loop = true;
        video.crossOrigin = "anonymous";
        video.muted = true;
        const texture = new VideoTexture(video);
        this.material.map = texture;
        video.play();
        document.addEventListener("visibilitychange", function () {
            if (!document.hidden) {
                video.play().catch((error) => {
                    console.log("Playback interrupted:", error);
                });
            }
            else {
                console.log("video paused");
                video.pause();
            }
        });
        this.videoElement = video;
    }
    meshToData() {
        const { position, rotation, scale, src, name, uuid, userData } = this;
        const { displayName, description } = userData;
        const res = new SceneItem();
        res.uuid = uuid;
        res.rotation = rotation;
        res.position = position;
        res.scale = scale;
        res.name = name;
        res.src = src;
        res.type = this.type;
        if (displayName)
            res.displayName = displayName;
        if (description)
            res.description = description;
        return res;
    }
    toJSON(meta) {
        const res = {};
        res.object = this.meshToData();
        return res;
    }
}
