import { ObjectLoader, } from "three";
import { SceneItem } from "threejs/model/SceneItem";
import { ImageMesh } from "threejs/sceneItem/ImageMesh";
import { ObjectMesh } from "threejs/sceneItem/ObjectMesh";
import { VideoMesh } from "threejs/sceneItem/VideoMesh";
import { LOAD_JSON_ITEMS } from "threejs/state/const";
export class YounivrObjectLoader extends ObjectLoader {
    constructor(_groupToAdd, _baseScene) {
        super();
        this._groupToAdd = _groupToAdd;
        this._baseScene = _baseScene;
    }
    parseObject(data, geometries, materials, animations) {
        const itemToAdd = new SceneItem();
        const { name, uuid, position, scale, src, rotation, displayName, description } = data;
        if (position)
            itemToAdd.position = position;
        if (scale)
            itemToAdd.scale = scale;
        if (rotation)
            itemToAdd.rotation = rotation;
        if (src)
            itemToAdd.src = src;
        if (uuid)
            itemToAdd.uuid = uuid;
        if (name)
            itemToAdd.name = name;
        if (displayName)
            itemToAdd.displayName = displayName;
        if (description)
            itemToAdd.description = description;
        if (data.name === "ImagePlane") {
            const mesh = new ImageMesh();
            mesh.dataToMesh(itemToAdd);
            this._groupToAdd.add(mesh);
            const customEvent = new CustomEvent(LOAD_JSON_ITEMS, {
                detail: mesh,
            });
            this._baseScene.renderer.domElement.dispatchEvent(customEvent);
        }
        else if (data.name === "VideoPlane") {
            const mesh = new VideoMesh();
            mesh.dataToMesh(itemToAdd);
            this._groupToAdd.add(mesh);
            const customEvent = new CustomEvent(LOAD_JSON_ITEMS, {
                detail: mesh,
            });
            this._baseScene.renderer.domElement.dispatchEvent(customEvent);
        }
        else if (data.name === "CustomObject") {
            const mesh = new ObjectMesh();
            mesh.dataToMesh(itemToAdd);
            this._groupToAdd.add(mesh);
            const customEvent = new CustomEvent(LOAD_JSON_ITEMS, {
                detail: mesh,
            });
            this._baseScene.renderer.domElement.dispatchEvent(customEvent);
        }
        return super.parseObject(data, geometries, materials, animations);
    }
}
