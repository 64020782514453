var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/**
=========================================================
* Material Dashboard 2 PRO React TS - v1.0.2
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { forwardRef } from "react";
// react-router-dom components
import { Link } from "react-router-dom";
// @mui material components
import Icon from "@mui/material/Icon";
// Material Dashboard 2 PRO React TS components
import MDBox from "components/generic/MDBox";
import MDTypography from "components/generic/MDTypography";
// custom styles for the DefaultItem
import defaultItemIconBox from "components/generic/Items/DefaultItem/styles";
import MDButton from "components/generic/MDButton";
const DefaultItem = forwardRef((_a, ref) => {
    var { color = "info", icon, title, subTitle, description, actions } = _a, rest = __rest(_a, ["color", "icon", "title", "subTitle", "description", "actions"]);
    return (_jsxs(MDBox, Object.assign({}, rest, { ref: ref, display: "flex", alignItems: "center", justifyContent: "space-between" }, { children: [icon && (_jsx(MDBox, Object.assign({ sx: (theme) => defaultItemIconBox(theme, { color }) }, { children: _jsx(Icon, { children: icon }) }))), _jsxs(MDBox, Object.assign({ ml: 2, mb: 1, lineHeight: 1.4 }, { children: [_jsx(MDTypography, Object.assign({ display: "block", variant: "button", fontWeight: "medium" }, { children: title })), _jsx(MDTypography, Object.assign({ variant: "button", fontWeight: "regular", color: "text" }, { children: subTitle }))] })), _jsx(MDBox, Object.assign({ ml: 2, mb: 1, lineHeight: 1.4 }, { children: _jsx(MDTypography, Object.assign({ variant: "button", fontWeight: "regular", color: "text" }, { children: description })) })), actions && (_jsx(MDBox, Object.assign({ display: "flex", justifyContent: "space-between", alignItems: "center" }, { children: actions === null || actions === void 0 ? void 0 : actions.map((action, key) => {
                    return action.type === "internal" ? (_jsx(MDBox, Object.assign({ ml: 1 }, { children: _jsx(MDButton, Object.assign({ component: Link, to: action.route, variant: "outlined", size: "small", color: action.color, circular: true, m: 2 }, { children: action.label })) }), key)) : (_jsx(MDBox, Object.assign({ ml: 1 }, { children: _jsx(MDButton, Object.assign({ component: "a", href: action.route, target: "_blank", rel: "noreferrer", variant: "outlined", size: "small", circular: true, color: action.color, ml: 2 }, { children: action.label })) }), key));
                }) })))] })));
});
export default DefaultItem;
