import { Fragment as _Fragment, jsx as _jsx } from "react/jsx-runtime";
import { useState } from "react";
import { useSelector } from "react-redux";
export function useFormPost(endpoint) {
    const [globalErrorMessage, setGlobalErrorMessage] = useState(null);
    const [status, setStatus] = useState(null);
    const [result, setResult] = useState(null);
    const [errors, setErrors] = useState(null);
    const [loading, setLoading] = useState(null);
    const userToken = useSelector((state) => state.user.userToken);
    //if (!userToken) signOut();
    const abortController = new AbortController();
    const execute = async (data) => {
        setStatus(0);
        try {
            setGlobalErrorMessage(_jsx(_Fragment, {}));
            const headers = new Headers();
            headers.append("Authorization", "Bearer " + userToken);
            if (typeof data === "string") {
                headers.append("Content-Type", "application/json");
            }
            const response = await fetch(endpoint.value, {
                headers: headers,
                body: data,
                method: "POST",
                signal: abortController.signal,
            });
            const res = await response.json();
            setStatus(response.status);
            if (response.status !== 200) {
                if (res.errors) {
                    setErrors(res.errors);
                }
                else if (res.message) {
                    setGlobalErrorMessage(_jsx("p", Object.assign({ style: { color: "red" } }, { children: res.message })));
                }
                console.log(res);
            }
            else {
                setResult(res);
            }
        }
        catch (error) {
            setLoading(false);
            console.log(error);
        }
    };
    return { globalErrorMessage, status, result, execute, loading, errors };
}
