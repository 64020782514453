import { CLOSE_SCENE_ITEM_EDITOR, LEFT_CLICK_IN_SCENE_MODEL, RIGHT_CLICK_IN_SCENE_MODEL, } from "../../state/const";
import React from "react";
import ReactDOM from "react-dom/client";
import ModelContextMenu from "../../component/ModelContextMenu";
import { CSS2DRenderer, CSS2DObject } from "three-stdlib";
export class EditInteractionService {
    constructor(_baseScene) {
        this._baseScene = _baseScene;
        this._baseScene.renderer.domElement.addEventListener(LEFT_CLICK_IN_SCENE_MODEL, () => {
            const customEvent = new CustomEvent(CLOSE_SCENE_ITEM_EDITOR);
            this._baseScene.renderer.domElement.dispatchEvent(customEvent);
        });
        this._baseScene.renderer.domElement.addEventListener(CLOSE_SCENE_ITEM_EDITOR, () => {
            this.hideMenu();
        });
        this._baseScene.renderer.domElement.addEventListener(RIGHT_CLICK_IN_SCENE_MODEL, (e) => {
            if (this._baseScene.sceneInteractionDetail) {
                const position = this._baseScene.sceneInteractionDetail.position;
                this.showMenu(position);
            }
        });
        this.make2DRenderer();
        this.createMenu();
        this.hideMenu();
    }
    make2DRenderer() {
        if (this.TwoDRenderer)
            return this.TwoDRenderer;
        this.TwoDRenderer = new CSS2DRenderer();
        this.TwoDRenderer.setSize(this._baseScene.renderer.domElement.parentElement.clientWidth, this._baseScene.renderer.domElement.parentElement.clientHeight);
        this.TwoDRenderer.domElement.style.position = "absolute";
        this.TwoDRenderer.domElement.style.top = "0px";
        this.TwoDRenderer.domElement.style.pointerEvents = "none";
        this._baseScene.renderer.domElement.parentElement.appendChild(this.TwoDRenderer.domElement);
        const prevCallBack = this._baseScene._animationCallBack;
        this._baseScene._animationCallBack = () => {
            prevCallBack();
            this.TwoDRenderer.render(this._baseScene.scene, this._baseScene.camera);
        };
        return this.TwoDRenderer;
    }
    createMenu() {
        const menuContainer = document.createElement("div");
        menuContainer.className = "menuContainer";
        ReactDOM.createRoot(menuContainer).render(React.createElement(ModelContextMenu, {
            scene: this._baseScene,
        }));
        const menu2DObject = new CSS2DObject(menuContainer);
        this.menuObject = menu2DObject;
        this._baseScene.scene.add(menu2DObject);
        this._contextMenuContainer = menuContainer;
        menuContainer.addEventListener("mouseup", (e) => {
            e.stopPropagation();
            // console.log("click on context menu");
            this.hideMenu();
        });
    }
    showMenu(position) {
        if (!this._contextMenuContainer)
            this.createMenu();
        // this.TwoDRenderer.domElement.style.pointerEvents = "all";
        this.menuObject.position.copy(position);
        this.menuObject.visible = true;
    }
    hideMenu() {
        if (!this._contextMenuContainer)
            return;
        this.menuObject.visible = false;
        // this.TwoDRenderer.domElement.style.pointerEvents = "none";
    }
}
