import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
/**
=========================================================
* Material Dashboard 2 PRO React TS - v1.0.2
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { useEffect, useState } from "react";
// @mui material components
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import Icon from "@mui/material/Icon";
// Material Dashboard 2 PRO React TS components
import MDBox from "components/generic/MDBox";
import MDTypography from "components/generic/MDTypography";
import { IconButton, Tooltip } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { HOSTNAME, url } from "services/ApiEndpoint";
import moment from "moment";
import FavoriteIcon from "@mui/icons-material/Favorite";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import { useFormGet } from "hooks/useFormGet";
import RoomDelete from "../RoomDelete";
function RoomCard({ room }) {
    const imgSrc = room.thumbnail
        ? room.thumbnail.includes(HOSTNAME)
            ? room.thumbnail
            : url(`/room/${room.thumbnail}`).value
        : "https://upload.wikimedia.org/wikipedia/commons/3/3f/Placeholder_view_vector.svg";
    const [u, SetUrl] = useState("");
    const { execute, status } = useFormGet(u);
    const navigate = useNavigate();
    const [fav, setFav] = useState(room.favorite);
    useEffect(() => {
        if (!u)
            return;
        execute();
    }, [u]);
    useEffect(() => {
        if (status === 200) {
            setFav(!fav);
        }
    }, [status]);
    // Action buttons for the BookingCard
    const actionButtons = (_jsxs(_Fragment, { children: [room.template ? (_jsxs(_Fragment, { children: [_jsx(Tooltip, Object.assign({ title: "Edit", placement: "bottom" }, { children: _jsx(MDTypography, Object.assign({ variant: "body1", color: "info", lineHeight: 1, sx: { cursor: "pointer", mx: 3 } }, { children: _jsx(IconButton, Object.assign({ color: "primary", "aria-label": "Edit", onClick: () => navigate(`/rooms/edit/${room._id}`) }, { children: _jsx(Icon, Object.assign({ color: "inherit" }, { children: "edit" })) })) })) })), _jsx(Tooltip, Object.assign({ title: "Favory", placement: "bottom" }, { children: _jsx(MDTypography, Object.assign({ variant: "body1", color: "primary", lineHeight: 1, sx: { cursor: "pointer", mx: 3 } }, { children: _jsx(IconButton, Object.assign({ onClick: () => {
                                    if (fav) {
                                        SetUrl(url(`/room/favorites/remove/${room._id}`));
                                    }
                                    else {
                                        SetUrl(url(`/room/favorites/add/${room._id}`));
                                    }
                                } }, { children: fav ? _jsx(FavoriteIcon, { color: "error" }) : _jsx(FavoriteBorderIcon, {}) })) })) }))] })) : null, _jsx(RoomDelete, { id: room._id, title: room.title })] }));
    return (_jsxs(Card, Object.assign({ sx: {
            "&:hover .card-header": {
                transform: "translate3d(0, -50px, 0)",
            },
        } }, { children: [_jsxs(MDBox, Object.assign({ position: "relative", borderRadius: "lg", mt: -3, mx: 2, className: "card-header", sx: { transition: "transform 300ms cubic-bezier(0.34, 1.61, 0.7, 1)" } }, { children: [_jsx(MDBox, { component: "img", src: imgSrc, alt: room.title, borderRadius: "lg", shadow: "md", width: "100%", height: "100%", position: "relative", zIndex: 1, crossOrigin: "anonymous" }), _jsx(MDBox, { borderRadius: "lg", shadow: "md", width: "100%", height: "100%", position: "absolute", left: 0, top: "0", sx: {
                            //backgroundImage: imgSrc,
                            // transform: "scale(0.94)",
                            backgroundImage: `${imgSrc}`,
                            backgroundRepeat: "no-repeat",
                            filter: "blur(12px)",
                            backgroundSize: "cover",
                        } })] })), _jsxs(MDBox, Object.assign({ textAlign: "center", pt: 3, px: 3 }, { children: [_jsx(MDBox, Object.assign({ display: "flex", justifyContent: "center", alignItems: "center", mt: actionButtons ? -8 : -4.25 }, { children: actionButtons })), _jsx(MDTypography, Object.assign({ variant: "h5", fontWeight: "regular", sx: { mt: 1.5 } }, { children: room.title })), _jsx(MDTypography, Object.assign({ variant: "body2", color: "text", sx: { mt: 1, mb: 0 } }, { children: room.description }))] })), _jsx(Divider, {}), _jsx(MDBox, Object.assign({ display: "flex", justifyContent: "right", alignItems: "center", pt: 0.5, pb: 3, px: 3, lineHeight: 1 }, { children: _jsxs(MDBox, Object.assign({ color: "text", display: "flex", alignItems: "center" }, { children: [_jsx(Icon, Object.assign({ color: "inherit", sx: { m: 0.5 } }, { children: "update" })), _jsx(MDTypography, Object.assign({ variant: "button", fontWeight: "light", color: "text" }, { children: moment(room.updatedAt).format("LL, LT") }))] })) }))] })));
}
export default RoomCard;
