import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Grid, IconButton, List, ListItemButton, ListItemIcon, ListItemText, ToggleButton, ToggleButtonGroup, } from "@mui/material";
import { useEffect, useState } from "react";
import { EDIT_SCENE_ITEM, RESET_EDIT_ITEM, SAVE_CUSTOM_ITEMS_DETAILS, SCENE_LOADED, UPDATE_UI, } from "threejs/state/const";
import videoIcon from "assets/images/icons/files/video-48.png";
import imageIcon from "assets/images/icons/files/image-48.png";
import objIcon from "assets/images/icons/files/obj-48.png";
import MenuOpenIcon from "@mui/icons-material/MenuOpen";
import { useFormik } from "formik";
import MDBox from "components/generic/MDBox";
import FormInputText from "components/generic/form/FormInputText";
import FormInputTextArea from "components/generic/form/FormInputTextArea";
import MDTypography from "components/generic/MDTypography";
import MDButton from "components/generic/MDButton";
import Image from "./EditorComponent/Image";
import Video from "./EditorComponent/Video";
import CustomObject from "./EditorComponent/CustomObject";
export default function ModelMediaEditor(props) {
    const [sceneItemList, setSceneItemList] = useState([]);
    const [selectedItem, setSelectedItem] = useState(null);
    const [baseScene, setBaseScene] = useState(null);
    useEffect(() => {
        window.addEventListener(SCENE_LOADED, (e) => {
            setBaseScene(e.detail);
            e.detail.domElement.addEventListener(UPDATE_UI, (e) => {
                setSceneItemList([...e.detail.itemList]);
                setSelectedItem(e.detail.selectedItem);
            });
        });
        return () => {
            window.removeEventListener(SCENE_LOADED, () => { });
        };
    }, []);
    const handleClickBack = () => {
        const customEvent2 = new CustomEvent(RESET_EDIT_ITEM);
        baseScene.domElement.dispatchEvent(customEvent2);
    };
    return baseScene ? (selectedItem ? (_jsxs(Grid, Object.assign({ container: true, spacing: 1 }, { children: [_jsxs(Grid, Object.assign({ item: true, xs: 12, sx: { display: "flex", justifyContent: "space-between" } }, { children: [_jsx(IconButton, Object.assign({ color: "primary", onClick: handleClickBack }, { children: _jsx(MenuOpenIcon, {}) })), _jsx(TransformControlItem, { transformControl: baseScene.transformControl })] })), _jsx(Grid, Object.assign({ item: true, xs: 12 }, { children: _jsx(ItemDetail, { item: selectedItem, roomId: props.roomId, baseScene: baseScene.domElement }) }))] }))) : (_jsx(List, { children: sceneItemList.map((element, index) => (_jsx(ItemInfo, { item: element, domElement: baseScene.domElement }, index))) }))) : null;
}
const ItemDetail = ({ item, roomId, baseScene, }) => {
    const element = item.meshToData();
    const formData = useFormik({
        initialValues: {
            displayName: "",
            description: "",
            mediaValue: "",
        },
        onSubmit: (values) => {
            // const oldValue = item.meshToData()
            const oldInfo = item.meshToData();
            oldInfo.displayName = values.displayName;
            oldInfo.description = values.description;
            oldInfo.src = values.mediaValue;
            item.dataToMesh(oldInfo);
            // console.log(values, item);
            const customEvent = new CustomEvent(SAVE_CUSTOM_ITEMS_DETAILS);
            baseScene.dispatchEvent(customEvent);
        },
    });
    useEffect(() => {
        const selectedItem = item.meshToData();
        formData.setFieldValue("description", selectedItem.description);
        formData.setFieldValue("displayName", selectedItem.displayName);
        formData.setFieldValue("mediaValue", selectedItem.src);
    }, [item]);
    return (_jsx(MDBox, Object.assign({ component: "form", role: "form", onSubmit: formData.handleSubmit, noValidate: true }, { children: _jsx(MDBox, Object.assign({ mt: 2, mx: 1 }, { children: _jsxs(Grid, Object.assign({ container: true, spacing: 1 }, { children: [_jsx(Grid, Object.assign({ item: true, xs: 12 }, { children: _jsx(FormInputText, { label: "Display Name", name: "displayName", formik: formData }) })), _jsx(Grid, Object.assign({ item: true, xs: 12 }, { children: _jsx(FormInputTextArea, { rows: 5, label: "Description", name: "description", formik: formData }) })), _jsxs(Grid, Object.assign({ item: true, xs: 12 }, { children: [_jsx(MDTypography, Object.assign({ component: "label", variant: "button", fontWeight: "regular", color: "text", textTransform: "capitalize" }, { children: "Media" })), element.type === "ImageMesh" ? (_jsx(Image, { item: item, formData: formData, id: roomId })) : element.type === "VideoMesh" ? (_jsx(Video, { item: item, formData: formData, id: roomId })) : element.type === "ObjectMesh" ? (_jsx(CustomObject, { item: item, formData: formData, id: roomId })) : null] })), _jsxs(Grid, Object.assign({ item: true, xs: 12, sx: { display: "flex", justifyContent: "space-around" } }, { children: [_jsx(MDButton, Object.assign({ type: "submit", variant: "gradient", color: "primary" }, { children: "save" })), _jsx(MDButton, Object.assign({ variant: "outlined", color: "primary", onClick: () => { } }, { children: "Delete" }))] }))] })) })) })));
};
const TransformControlItem = ({ transformControl }) => {
    const [currentMode, setCurrentMode] = useState(transformControl.mode);
    const handleTransformationMode = (event, newTransformation) => {
        if (newTransformation !== null) {
            transformControl.mode = newTransformation;
            setCurrentMode(newTransformation);
        }
    };
    return (_jsx(MDBox, Object.assign({ mt: 1.625, mx: 1, sx: {
            display: "flex",
            justifyContent: "space-between",
        } }, { children: _jsxs(ToggleButtonGroup, Object.assign({ color: "primary", value: currentMode, exclusive: true, onChange: handleTransformationMode, "aria-label": "text alignment" }, { children: [_jsx(ToggleButton, Object.assign({ value: "translate", "aria-label": "Translate" }, { children: "Translate" })), _jsx(ToggleButton, Object.assign({ value: "scale", "aria-label": "Scale" }, { children: "Scale" })), _jsx(ToggleButton, Object.assign({ value: "rotate", "aria-label": "Rotate" }, { children: "Rotate" }))] })) })));
};
const ItemInfo = ({ item, domElement, }) => {
    const element = item.meshToData();
    return (_jsxs(ListItemButton, Object.assign({ component: "li", onClick: () => {
            const customEvent = new CustomEvent(EDIT_SCENE_ITEM, {
                detail: item,
            });
            domElement.dispatchEvent(customEvent);
        } }, { children: [_jsx(ListItemIcon, { children: _jsxs(IconButton, { children: [element.type === "VideoMesh" && (_jsx("img", { src: videoIcon, alt: "Video Icon", style: { width: 24, height: 24 } })), element.type === "ImageMesh" && (_jsx("img", { src: objIcon, alt: "Video Icon", style: { width: 24, height: 24 } })), element.type === "ObjectMesh" && (_jsx("img", { src: imageIcon, alt: "Video Icon", style: { width: 24, height: 24 } }))] }) }), _jsx(ListItemText, { color: "text", primary: element.displayName, primaryTypographyProps: { sx: { cursor: "pointer", fontSize: "0.875rem" } } })] })));
};
