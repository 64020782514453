import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button, Dialog, DialogActions, DialogContent, Grid, IconButton, Input, } from "@mui/material";
import { useEffect, useState } from "react";
import { url } from "services/ApiEndpoint";
import EditIcon from "@mui/icons-material/Edit";
import { useFormPost } from "hooks/useFormPost";
import { LoadingButton } from "@mui/lab";
import MDAvatar from "components/generic/MDAvatar";
import MDBox from "components/generic/MDBox";
export default function ProfileImage({ profileImage }) {
    const [image, setImage] = useState(url(`/user/media/${profileImage}`).value);
    const [updatedImage, setUpdatedImage] = useState(url(`/user/media/${profileImage}`).value);
    const [updatedImageValue, setUpdatedImageValue] = useState(null);
    const { execute, result, loading, status } = useFormPost(url(`/user/media/update-profile-image`));
    const [open, setOpen] = useState(false);
    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };
    const changeImage = (event) => {
        const files = event.target.files;
        if (!files)
            return;
        const file = URL.createObjectURL(files[0]);
        setUpdatedImage(file);
        setUpdatedImageValue(files[0]);
    };
    const uploadProfile = () => {
        const formData = new FormData();
        formData.append("update-profile-image", updatedImageValue);
        execute(formData);
    };
    useEffect(() => {
        console.log(status);
        if (status && status === 200) {
            setImage(updatedImage);
            handleClose();
        }
    }, [status]);
    return (_jsxs(MDBox, Object.assign({ sx: { display: "flex" } }, { children: [_jsx(MDAvatar, Object.assign({ src: image, alt: "profile-image", size: "xl", shadow: "sm", crossOrigin: "anonymous" }, { children: _jsx("img", { src: image, style: {
                        height: "100%",
                        width: "100%",
                        objectFit: "cover",
                    }, title: "", alt: "", crossOrigin: "anonymous" }) })), _jsx(IconButton, Object.assign({ onClick: handleClickOpen, color: "secondary", "aria-label": "Edit" }, { children: _jsx(EditIcon, {}) })), _jsxs(Dialog, Object.assign({ open: open, onClose: handleClose, "aria-labelledby": "alert-dialog-title", "aria-describedby": "alert-dialog-description" }, { children: [_jsx(DialogContent, { children: _jsxs(Grid, Object.assign({ container: true }, { children: [image ? (_jsx(Grid, Object.assign({ item: true, height: 150, marginBottom: 2 }, { children: _jsx("img", { src: updatedImage, style: {
                                            height: "100%",
                                            width: "100%",
                                            objectFit: "contain",
                                        }, alt: "", title: "", crossOrigin: "anonymous" }) }))) : null, _jsx(Grid, Object.assign({ item: true }, { children: _jsx(Input, { type: "file", onChange: changeImage }) }))] })) }), _jsx(DialogActions, { children: loading ? (_jsx(LoadingButton, Object.assign({ loading: true, variant: "outlined" }, { children: "Update" }))) : (_jsxs(Button, Object.assign({ type: "submit", variant: "outlined", onClick: uploadProfile }, { children: [" ", "Update", " "] }))) })] }))] })));
}
