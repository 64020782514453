import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Icon, Stack, Tab, Tabs, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useFormGet } from "hooks/useFormGet";
import { url } from "services/ApiEndpoint";
import VideoConference from "components/features/user/page/VideoConference";
import DashboardLayout from "components/features/LayoutContainers/DashboardLayout";
import DashboardNavbar from "components/generic/Navbars/DashboardNavbar";
import ModelLoader from "components/features/conference/ModelLoader";
export default function Conference() {
    const [selectedTab, setSelectedTab] = useState(0);
    const handleTabChange = (event, newValue) => {
        setSelectedTab(newValue);
    };
    const { id } = useParams();
    // get template by event ID
    const { execute, result } = useFormGet(url(`/scene-template/get-template-by-event/${id}`));
    const { execute: userInfoQuery, result: userInfo } = useFormGet(url("/user/info"));
    useEffect(() => {
        execute();
        userInfoQuery();
    }, []);
    return (_jsxs(DashboardLayout, { children: [_jsx(DashboardNavbar, {}), _jsxs(Stack, Object.assign({ direction: "column", spacing: 0, style: {
                    height: `calc(100vh - 130px)`,
                    display: "flex",
                    flexGrow: 1,
                    overflow: "auto",
                } }, { children: [_jsxs(Tabs, Object.assign({ value: selectedTab, onChange: handleTabChange, indicatorColor: "primary", textColor: "primary" }, { children: [_jsx(Tab, { label: "Video-conf", icon: _jsx(Icon, Object.assign({ fontSize: "small", sx: { mt: -0.25 } }, { children: "home" })) }), _jsx(Tab, { label: "Scene", icon: _jsx(Icon, Object.assign({ fontSize: "small", sx: { mt: -0.25 } }, { children: "home" })) }), _jsx(Tab, { label: "Media", icon: _jsx(Icon, Object.assign({ fontSize: "small", sx: { mt: -0.25 } }, { children: "home" })) })] })), _jsx(Typography, Object.assign({ component: "div", style: { height: "100%", borderRadius: "10px", overflow: "clip" }, hidden: selectedTab !== 0 }, { children: _jsx(VideoConference, {}) })), _jsx(Typography, Object.assign({ component: "div", style: { flexGrow: 1, overflow: "auto", height: "100%" }, hidden: selectedTab !== 1 }, { children: result && result.template ? (_jsx(ModelLoader, { template: result.template, userInfo: userInfo, eventId: id })) : (_jsx(Typography, Object.assign({ component: "div", style: { height: "100%", borderRadius: "10px", overflow: "clip" } }, { children: "No Template Found" }))) }))] }))] }));
}
