import { CLOSE_SCENE_ITEM_EDITOR, EDIT_SCENE_ITEM, ITEM_ADDED_TO_THE_SCENE_GROUP, UPDATE_UI, RESET_EDIT_ITEM, DELETE_ITEM_IN_SCENE_MODEL, SAVE_CUSTOM_ITEMS_DETAILS, LOAD_JSON_ITEMS, } from "../../state/const";
import { SceneItem } from "threejs/model/SceneItem";
import { Euler, Vector3 } from "three";
export class EditModel {
    constructor(_baseScene) {
        this._baseScene = _baseScene;
        this._itemList = [];
        this._selectedItem = null;
        this._lastSelectedItemDetail = null;
        this._newItemAdded = false;
        this._baseScene.renderer.domElement.addEventListener(EDIT_SCENE_ITEM, (e) => {
            this._selectedItem = e.detail;
            this._baseScene.transformControl.visible = true;
            this._baseScene.transformControl.object = e.detail;
            this.backupSelectedObjectData();
            this.triggerUpdateUIEvent();
        });
        this._baseScene.renderer.domElement.addEventListener(CLOSE_SCENE_ITEM_EDITOR, (e) => {
            this._baseScene.sceneInteractionDetail = null;
            this._baseScene.transformControl.visible = false;
            this._baseScene.transformControl.object = undefined;
            this._selectedItem = null;
            this._lastSelectedItemDetail = null;
            this._newItemAdded = false;
            this.triggerUpdateUIEvent();
        });
        this._baseScene.renderer.domElement.addEventListener(ITEM_ADDED_TO_THE_SCENE_GROUP, (e) => {
            this._newItemAdded = true;
            this._itemList.push(e.detail);
            this.triggerUpdateUIEvent();
        });
        this._baseScene.renderer.domElement.addEventListener(LOAD_JSON_ITEMS, (e) => {
            this._itemList.push(e.detail);
            this.triggerUpdateUIEvent();
        });
        this._baseScene.renderer.domElement.addEventListener(SAVE_CUSTOM_ITEMS_DETAILS, (e) => {
            const customEvent = new CustomEvent(CLOSE_SCENE_ITEM_EDITOR, {
                detail: {
                    itemList: this._itemList,
                    selectedItem: this._selectedItem,
                },
            });
            this._baseScene.renderer.domElement.dispatchEvent(customEvent);
        });
        this._baseScene.renderer.domElement.addEventListener(DELETE_ITEM_IN_SCENE_MODEL, (e) => {
            // mesh to operation
            const m = e.detail;
            const obj = this._baseScene.scene.getObjectByName("additionalData");
            // remove the from from the scene
            obj.remove(m);
            // remove from the list
            this._itemList = this._itemList.filter((item) => {
                return item.uuid !== m.uuid;
            });
            console.log(this._itemList);
            // send close editor event
            const customEvent = new CustomEvent(SAVE_CUSTOM_ITEMS_DETAILS, {
                detail: {
                    itemList: this._itemList,
                    selectedItem: this._selectedItem,
                },
            });
            this._baseScene.renderer.domElement.dispatchEvent(customEvent);
        });
        this._baseScene.renderer.domElement.addEventListener(RESET_EDIT_ITEM, (e) => {
            let customEvent;
            if (this._newItemAdded) {
                customEvent = new CustomEvent(DELETE_ITEM_IN_SCENE_MODEL, {
                    detail: this._selectedItem,
                });
            }
            else {
                this._selectedItem.dataToMesh(this._lastSelectedItemDetail);
                customEvent = new CustomEvent(CLOSE_SCENE_ITEM_EDITOR, {
                    detail: {
                        itemList: this._itemList,
                        selectedItem: this._selectedItem,
                    },
                });
            }
            this._baseScene.renderer.domElement.dispatchEvent(customEvent);
        });
    }
    triggerUpdateUIEvent() {
        const customEvent = new CustomEvent(UPDATE_UI, {
            detail: {
                itemList: this._itemList,
                selectedItem: this._selectedItem,
            },
        });
        this._baseScene.renderer.domElement.dispatchEvent(customEvent);
    }
    backupSelectedObjectData() {
        if (this._selectedItem) {
            const item = new SceneItem();
            const info = this._selectedItem.meshToData();
            item.description = info.description;
            item.displayName = info.displayName;
            item.position = new Vector3().copy(info.position);
            item.rotation = new Euler().copy(info.rotation);
            item.scale = new Vector3().copy(info.scale);
            item.src = info.src;
            item.type = info.type;
            item.uuid = info.uuid;
            item.name = info.name;
            this._lastSelectedItemDetail = item;
        }
    }
}
