import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import { useFormik } from "formik";
import FormSubmitButton from "components/generic/form/FormSubmitButton";
import FormInputTextArea from "components/generic/form/FormInputTextArea";
import FormInputText from "components/generic/form/FormInputText";
import { useFormPost } from "hooks/useFormPost";
import { url } from "services/ApiEndpoint";
import TemplateTypes from "./field/TemplateTypes";
import { Button } from "@mui/material";
import { Vector3, Vector4 } from "three";
import { useShowConfirmation } from "hooks/useShowConfirmation";
import FormImage from "components/generic/form/FormImage";
import FormFileInput from "components/generic/form/FormFileInput";
import { ApiFormErrorResolver } from "utils/ApiFormErrorResolver";
import { SCENE_PLAYER_LOADED } from "threejs/state/const";
const ThreeDPoints = ({ x, y, z }) => {
    return (_jsxs(_Fragment, { children: [_jsx("input", { type: "text", placeholder: "x-value", value: x, disabled: true }), _jsx("input", { type: "text", placeholder: "y-value", value: y, disabled: true }), _jsx("input", { type: "text", placeholder: "z-value", value: z, disabled: true })] }));
};
const FourDPoints = ({ x, y, z, w }) => {
    return (_jsxs(_Fragment, { children: [_jsx("input", { type: "text", placeholder: "x-value", value: x, disabled: true }), _jsx("input", { type: "text", placeholder: "y-value", value: y, disabled: true }), _jsx("input", { type: "text", placeholder: "z-value", value: z, disabled: true }), _jsx("input", { type: "text", placeholder: "w-value", value: w, disabled: true })] }));
};
const PositionRotationValue = (props) => {
    const [position, setPosition] = useState(new Vector3());
    const [rotation, setRotation] = useState(new Vector4());
    const [player, setPlayer] = useState(null);
    useEffect(() => {
        if (!player)
            return;
        const po = props.formData.initialValues.initPosition;
        setPosition(new Vector3(po.x, po.y, po.z));
        const ro = props.formData.initialValues.initRotation;
        setRotation(new Vector4(ro.x, ro.y, ro.z, ro.w));
        // player.position = po;
        // player.rotation = ro;
    }, [player]);
    window.addEventListener(SCENE_PLAYER_LOADED, (e) => {
        setPlayer(e.detail);
    });
    const displayValue = () => {
        if (!player)
            return;
        const pos = player.position;
        const rot = player.rotation;
        setPosition(pos);
        const rota = new Vector4(rot.x, rot.y, rot.z, rot.w);
        setRotation(rota);
        props.formData.setFieldValue("initPosition", pos);
        props.formData.setFieldValue("initRotation", rota);
    };
    return (_jsxs(_Fragment, { children: [_jsx("label", { children: "Position" }), _jsx(ThreeDPoints, Object.assign({}, position)), _jsx("label", { children: "Rotation" }), _jsx(FourDPoints, Object.assign({}, rotation)), _jsx(Button, Object.assign({ onClick: displayValue }, { children: "getPositionAndRotationOfThePlayer" }))] }));
};
export default function TemplateForm({ value }) {
    const { execute, globalErrorMessage, status, loading, errors } = useFormPost(url(`/scene-template/update-template/${value._id}`));
    const { Message, setShow, setDescription, setTitle } = useShowConfirmation();
    useEffect(() => {
        if (status === 200) {
            setTitle("Template Data Update");
            setDescription("Votre Modification est bien pris en compte");
            setShow(true);
        }
        else if (status === 400) {
            ApiFormErrorResolver(formData, errors);
        }
    }, [status]);
    useEffect(() => {
        console.log(value);
    }, []);
    const formData = useFormik({
        initialValues: value,
        onSubmit: async (submitedValue) => {
            const form = new FormData();
            form.append("title", submitedValue.title);
            form.append("description", submitedValue.description);
            form.append("model", submitedValue.model);
            form.append("thumbnail", submitedValue.thumbnail);
            form.append("type", submitedValue.type);
            form.append("initPosition", JSON.stringify(submitedValue.initPosition));
            form.append("initRotation", JSON.stringify(submitedValue.initRotation));
            // console.log();
            await execute(form);
        },
    });
    return (_jsxs(_Fragment, { children: [globalErrorMessage, _jsxs("form", Object.assign({ onSubmit: formData.handleSubmit }, { children: [_jsx(TemplateTypes, { formik: formData, name: "type", label: "Type" }), _jsx(FormInputText, { formik: formData, name: "title", label: "Title" }), _jsx(FormInputTextArea, { formik: formData, name: "description", label: "Description", rows: 5 }), _jsx(FormFileInput, { name: "model", label: "Model", formik: formData }), _jsx(FormImage, { formik: formData, name: "thumbnail", label: "Thumbnail" }), value.type === "Native" ? _jsx(PositionRotationValue, { formData: formData }) : null, _jsx("div", Object.assign({ style: { display: "flex", justifyContent: "center" } }, { children: _jsx(FormSubmitButton, { isSubmitting: formData.isSubmitting, text: "Submit" }) }))] })), _jsx(Message, {})] }));
}
