import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Grid, Paper, Stack } from "@mui/material";
import SceneLoader from "threejs/component/SceneLoader";
import { useFormGet } from "hooks/useFormGet";
import { url } from "services/ApiEndpoint";
import { useEffect, useState } from "react";
import CustomPopup from "threejs/component/CustomPopup";
import { SCENE_PLAYER_LOADED } from "threejs/state/const";
import { Vector3, Vector4 } from "three";
import MDBox from "components/generic/MDBox";
export default function ModelLoader(props) {
    const { execute, result } = useFormGet(url("/event/get/" + props.eventId));
    const [player, setPlayer] = useState(null);
    useEffect(() => {
        execute();
        // return () => {
        //   const cleanUpEvent = new CustomEvent(MODEL_CLEAN_UP_EVENT);
        //   window.dispatchEvent(cleanUpEvent);
        // };
    }, []);
    window.addEventListener(SCENE_PLAYER_LOADED, (e) => {
        setPlayer(e.detail);
    });
    useEffect(() => {
        if (!player)
            return;
        const po = props.template.initPosition;
        player.position = new Vector3(po.x, po.y, po.z);
        const ro = props.template.initRotation;
        player.rotation = new Vector4(ro.x, ro.y, ro.z, ro.w);
        console.log(po, ro);
    }, [player]);
    useEffect(() => {
        console.log(props.userInfo, result);
    }, [props.userInfo, result]);
    return props.userInfo && result ? (_jsxs(Stack, Object.assign({ direction: "column", style: { display: "flex", flexGrow: 1, overflow: "auto", height: "100%" } }, { children: [_jsx(Paper, Object.assign({ variant: "outlined", square: true, sx: {
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                    overflow: "auto",
                } }, { children: _jsxs(MDBox, Object.assign({ sx: { height: "100%", width: "100%" } }, { children: [_jsx(Grid, Object.assign({ container: true, sx: { height: "100%" } }, { children: _jsx(Grid, Object.assign({ item: true, xs: 12, md: 12, lg: 12, sx: { flexGrow: 1 } }, { children: _jsx(MDBox, { id: "scene-container", style: { width: "100%", height: "100%" } }) })) })), _jsx(CustomPopup, {})] })) })), _jsx(SceneLoader, { mode: "Presentation", containerId: "scene-container", templateInfo: props.template, userInfo: props.userInfo, roomInfo: result.room, eventId: props.eventId })] }))) : null;
}
