import { BoxGeometry, Mesh, MeshBasicMaterial, Vector3 } from "three";
import { Nametag } from "./Nametag";
import { KankanScene } from "../SceneManagement/KankanScene";
export class KankanAvatar {
    constructor(_baseScene, _name, _visible) {
        this._baseScene = _baseScene;
        this._name = _name;
        this._visible = _visible;
        if (this._baseScene instanceof KankanScene) {
            this._baseScene.getInstance().Camera.on("flying.ended", (e) => {
                this.updatePlayerPosition(new Vector3(e.currentPosition.x, e.currentPosition.y - 0.7, e.currentPosition.z));
                // this.loadPhysics();
            });
        }
    }
    addPlayerModel() {
        const mesh = new Mesh(new BoxGeometry(0.2, 0.2, 0.2), new MeshBasicMaterial({ color: "red" }));
        if (this._baseScene instanceof KankanScene) {
            const pose = this._baseScene.getInstance().Camera.getPose();
            mesh.position.x = pose.position.x;
            mesh.position.y = pose.position.y - 0.7;
            mesh.position.z = pose.position.z;
        }
        // mesh.position.y = 0.5;
        this._baseScene.scene.add(mesh);
        this._currentPlayer = mesh;
        this.addNameTag(mesh);
        mesh.material.needsUpdate = true;
        mesh.matrixWorldNeedsUpdate = true;
        // Disable frustum culling if necessary
        mesh.frustumCulled = false;
        mesh.visible = this._visible;
        this._baseScene.renderer.render(this._baseScene.scene, this._baseScene.camera);
        console.log(this._currentPlayer);
    }
    _deletePlayer() {
        this._currentPlayer.geometry.dispose();
        this._baseScene.scene.remove(this._currentPlayer);
        this._baseScene.scene.remove(this._currentPlayerNameTag);
        this._currentPlayer = undefined;
        this._currentPlayerNameTag = undefined;
    }
    addNameTag(mesh) {
        const _name = new Nametag(this._baseScene.scene);
        const _nameTag = _name.createNametag(10, 40, this._name);
        if (_nameTag) {
            _nameTag.position.y = mesh.position.y + 0.3;
            this._baseScene.scene.add(_nameTag);
            this._currentPlayerNameTag = _nameTag;
            this._currentPlayerNameTag.visible = this._visible;
        }
    }
    updatePlayerPosition(newPosition) {
        if (this._currentPlayer) {
            this._currentPlayer.position.copy(newPosition);
            if (this._currentPlayerNameTag) {
                this._currentPlayerNameTag.position.x = newPosition.x;
                this._currentPlayerNameTag.position.y = newPosition.y + 0.3;
                this._currentPlayerNameTag.position.z = newPosition.z;
            }
        }
    }
}
