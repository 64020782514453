import { jsx as _jsx } from "react/jsx-runtime";
/* eslint-disable no-unused-vars */
/**
=========================================================
* Material Dashboard 2 PRO React TS - v1.0.2
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
/**
  This file is used for controlling the global states of the components,
  you can customize the states for the different components here.
*/
import { createContext, useContext, useMemo, useReducer, } from "react";
// The Material Dashboard 2 PRO React TSUI Dashboard PRO Material main context
const MaterialUI = createContext(null);
// Setting custom name for the context which is visible on react dev tools
MaterialUI.displayName = "MaterialUIContext";
// Material Dashboard 2 PRO React reducer
function reducer(state, action) {
    switch (action.type) {
        case "MINI_SIDENAV": {
            return Object.assign(Object.assign({}, state), { miniSidenav: action.value });
        }
        case "TRANSPARENT_SIDENAV": {
            return Object.assign(Object.assign({}, state), { transparentSidenav: action.value });
        }
        case "WHITE_SIDENAV": {
            return Object.assign(Object.assign({}, state), { whiteSidenav: action.value });
        }
        case "SIDENAV_COLOR": {
            return Object.assign(Object.assign({}, state), { sidenavColor: action.value });
        }
        case "TRANSPARENT_NAVBAR": {
            return Object.assign(Object.assign({}, state), { transparentNavbar: action.value });
        }
        case "FIXED_NAVBAR": {
            return Object.assign(Object.assign({}, state), { fixedNavbar: action.value });
        }
        case "OPEN_CONFIGURATOR": {
            return Object.assign(Object.assign({}, state), { openConfigurator: action.value });
        }
        case "DIRECTION": {
            return Object.assign(Object.assign({}, state), { direction: action.value });
        }
        case "LAYOUT": {
            return Object.assign(Object.assign({}, state), { layout: action.value });
        }
        case "DARKMODE": {
            return Object.assign(Object.assign({}, state), { darkMode: action.value });
        }
        default: {
            throw new Error(`Unhandled action type: ${action.type}`);
        }
    }
}
// Material Dashboard 2 PRO React context provider
function MaterialUIControllerProvider({ children, }) {
    const initialState = {
        miniSidenav: false,
        transparentSidenav: false,
        whiteSidenav: true,
        sidenavColor: "primary",
        transparentNavbar: true,
        fixedNavbar: false,
        openConfigurator: false,
        direction: "ltr",
        layout: "dashboard",
        darkMode: false,
    };
    const [controller, dispatch] = useReducer(reducer, initialState);
    const value = useMemo(() => [controller, dispatch], [controller, dispatch]);
    return _jsx(MaterialUI.Provider, Object.assign({ value: value }, { children: children }));
}
// Material Dashboard 2 PRO React custom hook for using context
function useMaterialUIController() {
    const context = useContext(MaterialUI);
    if (!context) {
        throw new Error("useMaterialUIController should be used inside the MaterialUIControllerProvider.");
    }
    return context;
}
// Context module functions
const setMiniSidenav = (dispatch, value) => dispatch({ type: "MINI_SIDENAV", value });
const setTransparentSidenav = (dispatch, value) => dispatch({ type: "TRANSPARENT_SIDENAV", value });
const setWhiteSidenav = (dispatch, value) => dispatch({ type: "WHITE_SIDENAV", value });
const setSidenavColor = (dispatch, value) => dispatch({ type: "SIDENAV_COLOR", value });
const setTransparentNavbar = (dispatch, value) => dispatch({ type: "TRANSPARENT_NAVBAR", value });
const setFixedNavbar = (dispatch, value) => dispatch({ type: "FIXED_NAVBAR", value });
const setOpenConfigurator = (dispatch, value) => dispatch({ type: "OPEN_CONFIGURATOR", value });
const setDirection = (dispatch, value) => dispatch({ type: "DIRECTION", value });
const setLayout = (dispatch, value) => dispatch({ type: "LAYOUT", value });
const setDarkMode = (dispatch, value) => dispatch({ type: "DARKMODE", value });
export { MaterialUIControllerProvider, useMaterialUIController, setMiniSidenav, setTransparentSidenav, setWhiteSidenav, setSidenavColor, setTransparentNavbar, setFixedNavbar, setOpenConfigurator, setDirection, setLayout, setDarkMode, };
