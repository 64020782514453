import { jsx as _jsx } from "react/jsx-runtime";
// @mui icons
import Icon from "@mui/material/Icon";
import NewEvent from "page/event/NewEvent";
import ListRoom from "page/room/ListRoom";
import NewRoom from "page/room/NewRoom";
import ListTemplate from "page/template/ListTemplate";
import NewTemplate from "page/template/NewTemplate";
import Translation from "components/features/translation/page/Translation";
import Home from "page/dashboard/Home";
import Calendar from "page/calendar/";
import Profile from "page/profile";
import Settings from "page/settings/Settings";
import NewProduct from "page/ecommerce/products/new-product";
import ProductPage from "page/ecommerce/products/product-page";
import OrderList from "page/ecommerce/orders/order-list";
import OrderDetails from "page/ecommerce/orders/order-details";
import Analytics from "page/dashboard/Analytics";
import Template from "page/admin/template";
import CreateTemplate from "page/admin/create-template";
import CreateAvatar from "page/admin/create-avatar";
import Avatar from "page/admin/avatar";
const routes = [
    {
        type: "collapse",
        name: "User",
        key: "user",
        icon: _jsx(Icon, Object.assign({ fontSize: "medium" }, { children: "person" })),
        // icon: <MDAvatar src={profilePicture} alt="User name" size="sm" />,
        collapse: [
            {
                name: "My Profile",
                key: "profile",
                route: "/user/profile",
                component: _jsx(Profile, {}),
            },
            {
                name: "Settings",
                key: "settings",
                route: "/user/settings",
                component: _jsx(Settings, {}),
            },
            {
                name: "Contacts",
                key: "contacts",
                route: "/contacts",
                component: _jsx(ListRoom, {}),
                icon: _jsx(Icon, Object.assign({ fontSize: "medium" }, { children: "contacts" })),
            },
        ],
    },
    { type: "divider", key: "divider-0" },
    {
        type: "collapse",
        name: "Dashboard",
        key: "dashboard",
        icon: _jsx(Icon, Object.assign({ fontSize: "medium" }, { children: "dashboard" })),
        collapse: [
            {
                name: "Home",
                key: "home",
                icon: _jsx(Icon, Object.assign({ fontSize: "medium" }, { children: "dashboard" })),
                route: "dashboard/home",
                component: _jsx(Home, {}),
            },
            {
                name: "Analytics",
                key: "analytics",
                icon: _jsx(Icon, Object.assign({ fontSize: "medium" }, { children: "dashboard" })),
                route: "dashboard/analytics",
                component: _jsx(Analytics, {}),
            },
        ],
    },
    {
        type: "collapse",
        name: "Environnements",
        key: "environnements",
        icon: _jsx(Icon, Object.assign({ fontSize: "medium" }, { children: "image" })),
        collapse: [
            {
                name: "Rooms",
                key: "rooms",
                route: "environnements/rooms",
                component: _jsx(ListRoom, {}),
                icon: _jsx(Icon, Object.assign({ fontSize: "medium" }, { children: "image" })),
            },
            {
                name: "New Room",
                key: "room",
                route: "environnements/rooms/room",
                component: _jsx(NewRoom, {}),
            },
            {
                name: "Templates",
                key: "templates",
                route: "environnements/templates",
                component: _jsx(ListTemplate, {}),
            },
            {
                name: "New Template",
                key: "template",
                route: "environnements/template",
                component: _jsx(NewTemplate, {}),
            },
        ],
    },
    {
        type: "collapse",
        name: "Events",
        key: "events",
        icon: _jsx(Icon, Object.assign({ fontSize: "medium" }, { children: "apps" })),
        collapse: [
            {
                name: "My Calendar",
                key: "events",
                route: "/events",
                // component: <MyCalendar />,
                component: _jsx(Calendar, {}),
            },
            {
                name: "New Event",
                key: "create",
                route: "/events/create",
                component: _jsx(NewEvent, {}),
            },
        ],
    },
    { type: "divider", key: "divider-1" },
    {
        type: "collapse",
        name: "Ecommerce",
        key: "ecommerce",
        icon: _jsx(Icon, Object.assign({ fontSize: "medium" }, { children: "shopping_basket" })),
        collapse: [
            {
                name: "New Product",
                key: "new-product",
                route: "/ecommerce/products/new-product",
                component: _jsx(NewProduct, {}),
            },
            {
                name: "Product Page",
                key: "product-page",
                route: "/ecommerce/products/product-page",
                component: _jsx(ProductPage, {}),
            },
            {
                name: "Order List",
                key: "order-list",
                route: "/ecommerce/orders/order-list",
                component: _jsx(OrderList, {}),
            },
            {
                name: "Order Details",
                key: "order-details",
                route: "/ecommerce/orders/order-details",
                component: _jsx(OrderDetails, {}),
            },
        ],
    },
    { type: "divider", key: "divider-2" },
    {
        type: "collapse",
        name: "Admin Tools",
        key: "admin",
        icon: _jsx(Icon, Object.assign({ fontSize: "medium" }, { children: "content_paste" })),
        collapse: [
            {
                name: "Translation",
                key: "translation",
                route: "admin/translation",
                component: _jsx(Translation, {}),
            },
            {
                name: "Data",
                key: "data",
                route: "/admin/data",
                component: _jsx(Translation, {}),
            },
            {
                name: "New Template",
                key: "new-admin-template",
                route: "/admin/new-template",
                component: _jsx(CreateTemplate, {}),
            },
            {
                name: "Templates",
                key: "admin-templates",
                route: "/admin/template",
                component: _jsx(Template, {}),
            },
            {
                name: "Avatar",
                key: "admin-avatar",
                route: "/admin/avatar",
                component: _jsx(Avatar, {}),
            },
            {
                name: "New Avatar",
                key: "new-admin-avatar",
                route: "/admin/new-avatar",
                component: _jsx(CreateAvatar, {}),
            },
        ],
    },
];
export default routes;
