import { jsx as _jsx } from "react/jsx-runtime";
import { JitsiMeeting } from "@jitsi/react-sdk";
import { useFormGet } from "hooks/useFormGet";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { url } from "services/ApiEndpoint";
export default function VideoConference() {
    var _a;
    const { id } = useParams();
    const domainName = "dev.meet.visio.younivr.com";
    const { execute: userInfoQuery, result: userInfo } = useFormGet(url("/user/info"));
    useEffect(() => {
        userInfoQuery();
    }, []);
    return id && userInfo ? (_jsx(JitsiMeeting, { configOverwrite: {
            welcomePage: {
                disabled: true,
            },
            prejoinConfig: {
                enabled: false,
            },
        }, userInfo: {
            displayName: (_a = userInfo.firstname) !== null && _a !== void 0 ? _a : userInfo.email,
            email: userInfo.email,
        }, domain: domainName, roomName: id, getIFrameRef: (iframeRef) => {
            iframeRef.style.height = "100%";
        } })) : null;
}
