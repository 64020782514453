import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Grid } from "@mui/material";
import SceneLoader from "threejs/component/SceneLoader";
import { useFormGet } from "hooks/useFormGet";
import { url } from "services/ApiEndpoint";
import { useEffect } from "react";
import MDBox from "components/generic/MDBox";
import ModelMediaEditor from "threejs/component/ModelMediaEditor";
export default function ModelLoader(props) {
    const { execute: userInfoQuery, result: userInfo } = useFormGet(url("/user/info"));
    useEffect(() => {
        userInfoQuery();
    }, []);
    return userInfo ? (_jsx(MDBox, Object.assign({ sx: { height: "100%", width: "100%" } }, { children: _jsxs(Grid, Object.assign({ container: true, sx: { height: "100%" } }, { children: [_jsxs(Grid, Object.assign({ item: true, xs: 12, md: 9, lg: 9, sx: { flexGrow: 1 } }, { children: [_jsx(MDBox, { id: "scene-container", style: { position: "relative", width: "100%", height: "100%" } }), _jsx(SceneLoader, { mode: "Edit", containerId: "scene-container", templateInfo: props.template, roomInfo: {
                                _id: props.roomId,
                            }, userInfo: userInfo })] })), _jsx(Grid, Object.assign({ item: true, xs: 12, md: 3, lg: 3, sx: { flexGrow: 1 } }, { children: _jsx(ModelMediaEditor, { roomId: props.roomId }) }))] })) }))) : null;
}
