import { DoubleSide, Mesh, MeshBasicMaterial, PlaneGeometry, TextureLoader } from "three";
import { SceneItem } from "../model/SceneItem";
export class ImageMesh extends Mesh {
    constructor() {
        super(new PlaneGeometry(1, 1), new MeshBasicMaterial());
        this.type = "ImageMesh";
        this.name = "ImagePlane";
        this.material.side = DoubleSide;
    }
    dataToMesh(data) {
        var _a;
        this.clear();
        this.position.copy(data.position);
        this.rotation.copy(data.rotation);
        this.scale.copy(data.scale);
        this.src = data.src;
        this.name = data.name;
        this.userData.displayName = (_a = data.displayName) !== null && _a !== void 0 ? _a : this.uuid;
        this.userData.description = data.description;
        data.uuid = this.uuid;
        this.updateSrc();
        this.material.needsUpdate;
    }
    meshToData() {
        const { position, rotation, scale, src, name, uuid, userData } = this;
        const { displayName, description } = userData;
        const res = new SceneItem();
        res.uuid = uuid;
        res.rotation = rotation;
        res.position = position;
        res.scale = scale;
        res.name = name;
        res.src = src;
        res.type = this.type;
        if (displayName)
            res.displayName = displayName;
        if (description)
            res.description = description;
        return res;
    }
    updateSrc() {
        const texture = new TextureLoader().load(this.src);
        this.material.map = texture;
    }
    toJSON(meta) {
        const res = {};
        res.object = this.meshToData();
        return res;
    }
}
