import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/**
=========================================================
* Material Dashboard 2 PRO React TS - v1.0.2
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { useEffect } from "react";
// formik components
import { useFormik } from "formik";
// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
// Material Dashboard 2 PRO React TS components
import MDBox from "components/generic/MDBox";
import MDButton from "components/generic/MDButton";
// NewTemplate page components
import TemplateInfo from "components/features/template/new-template/TemplateInfo";
// import RoomVisibility from "components/features/room/new-room/RoomVisibility";
// NewUser layout schemas for form and form feilds
import { useFormPost } from "hooks/useFormPost";
import { url } from "services/ApiEndpoint";
import { ApiFormErrorResolver } from "utils/ApiFormErrorResolver";
function NewTemplateForm() {
    const { execute, globalErrorMessage, status, loading, errors } = useFormPost(url("/scene-template/create-template"));
    useEffect(() => {
        if (!status)
            return;
        if (status === 400) {
            ApiFormErrorResolver(formData, errors);
        }
        else if (status === 200) {
            window.location.href = "/environnements/templates";
        }
    }, [status]);
    const submitForm = async (values) => {
        // await execute(JSON.stringify(values));
        const form = new FormData();
        form.append("type", values.type);
        form.append("title", values.title);
        form.append("description", values.description);
        if (values.thumbnail)
            form.append("thumbnail", values.thumbnail);
        if (values.model)
            form.append("model", values.model);
        console.log(form);
        await execute(form);
    };
    const initValues = {
        type: "4DAGE",
        title: "",
        description: "",
        thumbnail: "",
        model: "",
    };
    const formData = useFormik({
        initialValues: initValues,
        onSubmit: async (value) => await submitForm(value),
    });
    return (_jsx(MDBox, Object.assign({ py: 3, mb: 20, height: "65vh" }, { children: _jsx(Grid, Object.assign({ container: true, justifyContent: "center", alignItems: "center", sx: { height: "100%", mt: 8 } }, { children: _jsx(Grid, Object.assign({ item: true, xs: 12, lg: 8 }, { children: _jsxs(Card, Object.assign({ sx: { height: "100%" } }, { children: [_jsx(MDBox, Object.assign({ mx: 2, mt: -3 }, { children: _jsx(Stepper, Object.assign({ alternativeLabel: true }, { children: _jsx(Step, { children: _jsx(StepLabel, { children: "Template Properties" }) }) })) })), _jsx(MDBox, Object.assign({ p: 3 }, { children: _jsx(MDBox, Object.assign({ component: "form", role: "form", onSubmit: formData.handleSubmit, noValidate: true }, { children: _jsxs(MDBox, { children: [_jsx(TemplateInfo, { formData: formData }), _jsxs(MDBox, Object.assign({ mt: 2, width: "100%", display: "flex", justifyContent: "space-between" }, { children: [_jsx(MDBox, {}), globalErrorMessage, _jsx(MDButton, Object.assign({ disabled: loading, type: "submit", variant: "gradient", color: "dark" }, { children: "create" }))] }))] }) })) }))] })) })) })) })));
}
export default NewTemplateForm;
